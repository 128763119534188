.schedule-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.schedule-img {
    object-fit: cover;
    object-position: 50% 10%;
    height: 70%;
    border-radius: 10px;
}



/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
