.db-carousel, .db-carousel-full {
    overflow: hidden;
    background-color: rgb(38, 38, 87);
    background-color: black;
}

.db-carousel {
    height: 80vh;
}

.db-carousel-full {
    height: 100vh;
}

.db-img {
    /* object-fit: cover; */
    /* height: 100%; */
    object-position: 0px 0px;
}

.db-groups-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 20vh;
    /* gap: 2px; */
    background-color: rgb(87, 87, 87);
}

.db-groups-grid-item {
    background-color: black;
    display: flex;
    gap: 2%;
    align-items: center;
    padding: 3%;
}

.db-groups-title {
    font-size: 2.8vw;
    font-weight: bold;
}

.db-color-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 100%;
    flex-grow: 1;
    /* width: 70%; */
}

.db-color-grid-item {
    height: 100%;
    /* border-style: solid; */
    /* border-color: red; */
    display: flex;
    align-items: center;
    padding: 5%;
}

.db-grid-seconds {
    grid-column-start: 1;
    grid-column-end: 4;
}

.db-color-card {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 100%;
    border-radius: 4%;
    box-shadow: 3px 3px 5px rgb(70, 70, 70);
}

.db-color-grid-item:first-child .db-color-card {
    height: 100%;
    widtH: 100%;
}

.db-color-card-text {
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
    font-size: 2.3vw;
    color: white;
    padding: 0px;
    margin: 0px;
}

/* =========================================== */

.row1 {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row2 {
    height: 90vh;
    position: relative;
}

.row3 {
    height: 0vh;
}

.row {
}

.dash-column-space {
    height: 2%;
}

.dashboard-logo {
    object-fit: contain;
    width: 12vw;
}

.head-title, .dashboard-title {
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 3.5vw;
    margin-bottom: 20px;
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
}

.head-title {
    color: rgb(255, 131, 131);
}

.dashboard-title {
    font-size: 3.5vw
}

.dashboard-section {
    /* border-style: solid; */
    border-color: rgb(44, 44, 44);
    position: relative;
    overflow: hidden;
}

li {
    color: white;
    font-size: 1.5vw;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
