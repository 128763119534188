.su-input-label {
  color: rgb(208, 208, 208);
}

.su-code-input {
  background-color: black;
  color: rgb(186, 186, 186);
  border-color: rgb(69, 69, 69);
}

.su-code-input:active, .su-code-input:focus {
  background-color: rgb(24, 24, 24);
  color: white;
}

.su-btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
