.announcement-item {
    font-size: 3vw;
    padding-top: 15px;
    padding-bottom: 15px;
}

.announcement-list {
    padding: 5%;
}

.blah {
    color: rgb(162, 162, 255);
    color: rgb(255, 163, 163);
    color: rgb(239, 255, 148);
    color: rgb(148, 255, 148);
    color: rgb(255, 148, 246);
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
