* {
    box-sizing: border-box;
}
@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
   }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
   }
}
@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
   }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
   }
}
.ticker-wrap {
    position: relative;
    /* bottom: 0; */
    width: 100%;
    overflow: hidden;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding-left: 100%;
    box-sizing: content-box;
}
.ticker-wrap .ticker {
    display: inline-block;
    /* height: 4rem; */
    height: 100%;
    /* line-height: 4rem; */
    /* line-height: 100%; */
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 15s;
}
.ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 3vw;
    color: white;
}
/* body {
    padding-bottom: 5rem;
} */
/* h1, h2, p {
    padding: 0 5%;
} */
