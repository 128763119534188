@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@700&display=swap');

.firebase-emulator-warning {
  opacity: 0.0;
  pointer-events: none;
}

.root {
  min-height: 100%;
}

body {
  background-color: black;
}

.btn-has-screen {
  position: relative;
}

h2, h3 {
  color: white;
}

.btn-screen {
  opacity: 0;
  /* background-color: rgba(255, 0, 0, 0.397); */
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
}

.app-desktop-only {
  display: none;
}

/* Dark theme */
/* p, h1, h2 {
  color: white;
}

body {
  background-color: black;
}

.survey-item-container {
  background-color: rgb(27, 27, 27);
} */
/* end dark theme */

.page-content {
  min-height: calc(100vh - 110px);
}

.app-header-cushion-top, .app-header-cushion-bottom {
  width: 100%;
  height: 22px;
}

.app-header-cushion-top {
  /* header color */
  background-color: rgb(248, 249, 250);
  /* background-color: white; */
  position: fixed;
  top: 0;
  z-index: 100;
}

.text-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

p {
  font-size: 14px;
}

/* #root {
  color: white;
  background-color: black;
} */

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
  .text-wrapper {
      padding-left: 30px;
      padding-right: 20vw;
  }
  
  p {
      font-size: 15px;
  }

}


@media only screen and (min-width: 1200px) {
  .text-wrapper {
      padding-left: 30px;
      padding-right: 40vw;
      margin-top: 50px;
      margin-bottom: 50px;
  }
  
  p {
      font-size: 15px;
  }

  .app-desktop-only {
    display: block;
  }
}
