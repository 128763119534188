.color-group-wrapper {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    /* background-color: red; */
    flex-wrap: wrap;
    justify-content: center;
}

.group-section-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    width: 50vw;
}

.group-card {
    border-style: solid;
    border-radius: 15px;
    border-color: rgb(255, 255, 255);
    position: relative;
    transition: 1s ease-in-out all;
}

.signup-group-wrapper {
    width: 60%;
    padding-top: 25%;
}

.signup-wrapper {
    box-shadow: 2px 3px 10px white;
}

.signup-wrapper-None .signup-group-text {
    background-color: rgba(0, 0, 0, 0);
}

.group-text {
    color: white;
    margin: 0px;
    padding: 0px;
    font-family: 'Carter One', cursive;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.signup-group-text {
    font-size: 7.5vw;
}

.dismissed-groups {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    /* position: relative; */
}

.eaten-group-wrapper {
    width: 40%;
    padding-top: 13%;
    margin: 10px;
}

.eaten-group-text {
    font-size: 2.5vw;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
