.hgs-main {

}

.hgs-switch {
  margin-left: 20px;
}

.hgs-switch .form-check-label, .hgs-group-label {
  color: rgb(220, 220, 220);
}

.hgs-color-checks {
  color: white;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
