.hp-page {
    padding: 20px;
}

.hp-page-section {
    margin-bottom: 40px;
}

.hp-title {
    font-size: 1.7rem;
}

.hp-title-3 {
    font-size: 1.2rem;
    color: rgb(196, 196, 196);
}

.hp-carousel-imgs {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.hp-carousel-item {
    background-color: rgb(66, 66, 66);
}

.hp-carousel-img {
    object-fit: cover;
    height: 100px;
}

.hp-img-x-anchor {
    height: 0px;
    width: 100%;
}

.hp-img-wrapper, .hp-img-hidden {
    height: 100px;
    width: 100%;
    position: relative;
    top: -100px;
    text-align: right;
    vertical-align: text-top;
    transition: all 0.15s ease-in-out;
    background-color: rgba(255, 120, 120, 0);
    border-style: none;
    border-width: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hp-img-hidden {
    background-color: rgba(0, 0, 0, 0.676);
}

.hp-img-hidden-icon {
    color: white;
    font-size: 2rem;
}

.hp-img-wrapper:hover {
    background-color: rgba(68, 68, 68, 0.896);
}

.hp-img-wrapper:hover .hp-img-three-dots {
    opacity: 100%;
}

.hp-img-wrapper:active {
    background-color: rgba(46, 46, 46, 0.541);
}

.hp-img-three-dots {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    opacity: 0%;
    transition: all 0.15s ease-in-out;
}

.checkbox-btns-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.hp-group-btns-grid {
    width: 100%;
}

.hp-btn-wrapper {
    width: 90vw;
}

.hp-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-bottom: 5px;
    width: 100%;
}

.hp-pink-btn,
.hp-pink-btn:focus,
.hp-pink-btn:hover {
    border-color: #ff8297;
    color: #ff8297;
    background-color: black;
}
.hp-pink-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-pink-btn-checked,
.hp-pink-btn-checked:focus,
.hp-pink-btn-checked:hover {
    background-color: #ff8297;
    color: black;
    border-color: #ff8297;
}

.hp-purple-btn,
.hp-purple-btn:focus,
.hp-purple-btn:hover {
    border-color: #a742cf;
    color: #d492ee;
    background-color: black;
}
.hp-purple-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-purple-btn-checked,
.hp-purple-btn-checked:focus,
.hp-purple-btn-checked:hover {
    background-color: #a742cf;
    color: white;
    border-color: #a742cf;
}

.hp-red-btn,
.hp-red-btn:focus,
.hp-red-btn:hover {
    border-color: #dc3545;
    color: #dc3545;
    background-color: black;
}
.hp-red-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-red-btn-checked,
.hp-red-btn-checked:focus,
.hp-red-btn-checked:hover {
    background-color: #dc3545;
    color: white;
    border-color: #dc3545;
}

.hp-yellow-btn,
.hp-yellow-btn:focus,
.hp-yellow-btn:hover {
    border-color: #ffc107;
    color: #ffc107;
    background-color: black;
}
.hp-yellow-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-yellow-btn-checked,
.hp-yellow-btn-checked:focus,
.hp-yellow-btn-checked:hover {
    background-color: #ffc107;
    color: black;
    border-color: #ffc107;
}

.hp-blue-btn,
.hp-blue-btn:focus,
.hp-blue-btn:hover {
    border-color: #0d6efd;
    color: #0d6efd;
    background-color: black;
}
.hp-blue-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-blue-btn-checked,
.hp-blue-btn-checked:focus,
.hp-blue-btn-checked:hover {
    background-color: #0d6efd;
    color: white;
    border-color: #0d6efd;
}

.hp-green-btn,
.hp-green-btn:focus,
.hp-green-btn:hover {
    border-color: #198754;
    color: #198754;
    background-color: black;
}
.hp-green-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-green-btn-checked,
.hp-green-btn-checked:focus,
.hp-green-btn-checked:hover {
    background-color: #198754;
    color: white;
    border-color: #198754;
}

.hp-seconds-btn,
.hp-seconds-btn:focus,
.hp-seconds-btn:hover {
    border-color: #ffffff;
    color: #ffffff;
    background-color: black;
}
.hp-seconds-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-seconds-btn-checked,
.hp-seconds-btn-checked:focus,
.hp-seconds-btn-checked:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-color: #ffffff;
}

.hp-btn-text {
    font-size: 100px;
    padding: 0px;
    margin: 0px;
}

/* Screen size specs */

@media only screen and (max-width: 400px) {
    .hp-btn {
        /* pointer-events: none; */
    }
}

.form-text {
    color: white;
    font-size: 15px;
}

.announcement-submit-btn-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.announcement-submit-btn {
    width: 60%;
    margin-bottom: 5px;
}


@media only screen and (min-width: 400px) {

}


@media only screen and (min-width: 767px) {
    .hp-page {
        padding: 40px;
    }

    .hp-upload-btn-container {
        display: flex;
        justify-content: center;
    }
    
    .hp-upload-btn {
        width: 250px;
    }

    .hp-groups-container {
        display: flex;
        justify-content: space-between;
    }

    .hp-group-section {
        width: 48%;
    }
}


@media only screen and (min-width: 1000px) {

}


@media only screen and (min-width: 1200px) {
    .hp-page {
        display: flex;
        justify-content: center;
    }

    .hp-content {
        display: grid;
        grid-template-columns: 60% 30%;
        gap: 80px;
        width: 100%;
    }

    .hp-title {
        font-size: 2rem;
    }

    .hp-upload-btn-container {
        display: block;
    }

    .hp-groups-section {
        /* order: -1; */
    }

    .hp-groups-container {
        display: block;
        width: 100%;
    }

    .hp-group-section {
        width: 100%;
    }

    .hp-group-btns-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;
    }
}
