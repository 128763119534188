@import url(https://fonts.googleapis.com/css2?family=Fira+Mono:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
    padding-left: 20px;
}

.navbar-profile-name {
    padding: 10px;
}

.nav-icon {
    margin-right: 2px;
}

.brand-link {
    color: rgb(255, 255, 255);
}

.brand-link:hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.auth-spinner-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-spinner {
    margin: 10px;
}
  
/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.upload-modal-warning {
    color: red;
    margin: 0px;
    padding: 0px;
    margin-bottom: 15px;
}

.pic-upload-preview-container {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.pic-upload-modal-profile-preview {
    height: 30vh;
    object-fit: cover;
    object-position: 0px 0px;
}

.pm-file-group {
    margin-bottom: 10px;
}

.pm-submit-btn {
    width: 80px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

/* Screen size specs */

.alert-confirm-code-instructions {
    padding: 0px;
    margin: 10px;
    color: gray;
}

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.epm-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.epm-btn-wrapper {
}

.epm-btn {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-style: none;
  box-shadow: 0px 0px 8px gray;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.epm-btn:active {
  box-shadow: 0px 0px 0px white;
}

.epm-vis-btn:active {
  background-color: rgb(210, 210, 210);
}

.epm-del-btn:active {
  background-color: rgb(171, 40, 54);
}

.epm-del-btn {
  background-color: rgb(220, 53, 69);
}

.epm-vis-btn-hidden {
  background-color: rgb(94, 94, 94)
}

.epm-btn-icon {
  font-size: 2rem;
}

.epm-btn-icon-white {
  color: white;
}

.epm-btn-title {
  font-size: 1rem;
  text-align: center;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.hgs-main {

}

.hgs-switch {
  margin-left: 20px;
}

.hgs-switch .form-check-label, .hgs-group-label {
  color: rgb(220, 220, 220);
}

.hgs-color-checks {
  color: white;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.hp-page {
    padding: 20px;
}

.hp-page-section {
    margin-bottom: 40px;
}

.hp-title {
    font-size: 1.7rem;
}

.hp-title-3 {
    font-size: 1.2rem;
    color: rgb(196, 196, 196);
}

.hp-carousel-imgs {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.hp-carousel-item {
    background-color: rgb(66, 66, 66);
}

.hp-carousel-img {
    object-fit: cover;
    height: 100px;
}

.hp-img-x-anchor {
    height: 0px;
    width: 100%;
}

.hp-img-wrapper, .hp-img-hidden {
    height: 100px;
    width: 100%;
    position: relative;
    top: -100px;
    text-align: right;
    vertical-align: text-top;
    transition: all 0.15s ease-in-out;
    background-color: rgba(255, 120, 120, 0);
    border-style: none;
    border-width: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hp-img-hidden {
    background-color: rgba(0, 0, 0, 0.676);
}

.hp-img-hidden-icon {
    color: white;
    font-size: 2rem;
}

.hp-img-wrapper:hover {
    background-color: rgba(68, 68, 68, 0.896);
}

.hp-img-wrapper:hover .hp-img-three-dots {
    opacity: 100%;
}

.hp-img-wrapper:active {
    background-color: rgba(46, 46, 46, 0.541);
}

.hp-img-three-dots {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    opacity: 0%;
    transition: all 0.15s ease-in-out;
}

.checkbox-btns-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.hp-group-btns-grid {
    width: 100%;
}

.hp-btn-wrapper {
    width: 90vw;
}

.hp-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-bottom: 5px;
    width: 100%;
}

.hp-pink-btn,
.hp-pink-btn:focus,
.hp-pink-btn:hover {
    border-color: #ff8297;
    color: #ff8297;
    background-color: black;
}
.hp-pink-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-pink-btn-checked,
.hp-pink-btn-checked:focus,
.hp-pink-btn-checked:hover {
    background-color: #ff8297;
    color: black;
    border-color: #ff8297;
}

.hp-purple-btn,
.hp-purple-btn:focus,
.hp-purple-btn:hover {
    border-color: #a742cf;
    color: #d492ee;
    background-color: black;
}
.hp-purple-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-purple-btn-checked,
.hp-purple-btn-checked:focus,
.hp-purple-btn-checked:hover {
    background-color: #a742cf;
    color: white;
    border-color: #a742cf;
}

.hp-red-btn,
.hp-red-btn:focus,
.hp-red-btn:hover {
    border-color: #dc3545;
    color: #dc3545;
    background-color: black;
}
.hp-red-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-red-btn-checked,
.hp-red-btn-checked:focus,
.hp-red-btn-checked:hover {
    background-color: #dc3545;
    color: white;
    border-color: #dc3545;
}

.hp-yellow-btn,
.hp-yellow-btn:focus,
.hp-yellow-btn:hover {
    border-color: #ffc107;
    color: #ffc107;
    background-color: black;
}
.hp-yellow-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-yellow-btn-checked,
.hp-yellow-btn-checked:focus,
.hp-yellow-btn-checked:hover {
    background-color: #ffc107;
    color: black;
    border-color: #ffc107;
}

.hp-blue-btn,
.hp-blue-btn:focus,
.hp-blue-btn:hover {
    border-color: #0d6efd;
    color: #0d6efd;
    background-color: black;
}
.hp-blue-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-blue-btn-checked,
.hp-blue-btn-checked:focus,
.hp-blue-btn-checked:hover {
    background-color: #0d6efd;
    color: white;
    border-color: #0d6efd;
}

.hp-green-btn,
.hp-green-btn:focus,
.hp-green-btn:hover {
    border-color: #198754;
    color: #198754;
    background-color: black;
}
.hp-green-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-green-btn-checked,
.hp-green-btn-checked:focus,
.hp-green-btn-checked:hover {
    background-color: #198754;
    color: white;
    border-color: #198754;
}

.hp-seconds-btn,
.hp-seconds-btn:focus,
.hp-seconds-btn:hover {
    border-color: #ffffff;
    color: #ffffff;
    background-color: black;
}
.hp-seconds-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.425);
}
.hp-seconds-btn-checked,
.hp-seconds-btn-checked:focus,
.hp-seconds-btn-checked:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-color: #ffffff;
}

.hp-btn-text {
    font-size: 100px;
    padding: 0px;
    margin: 0px;
}

/* Screen size specs */

@media only screen and (max-width: 400px) {
    .hp-btn {
        /* pointer-events: none; */
    }
}

.form-text {
    color: white;
    font-size: 15px;
}

.announcement-submit-btn-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.announcement-submit-btn {
    width: 60%;
    margin-bottom: 5px;
}


@media only screen and (min-width: 400px) {

}


@media only screen and (min-width: 767px) {
    .hp-page {
        padding: 40px;
    }

    .hp-upload-btn-container {
        display: flex;
        justify-content: center;
    }
    
    .hp-upload-btn {
        width: 250px;
    }

    .hp-groups-container {
        display: flex;
        justify-content: space-between;
    }

    .hp-group-section {
        width: 48%;
    }
}


@media only screen and (min-width: 1000px) {

}


@media only screen and (min-width: 1200px) {
    .hp-page {
        display: flex;
        justify-content: center;
    }

    .hp-content {
        display: grid;
        grid-template-columns: 60% 30%;
        grid-gap: 80px;
        gap: 80px;
        width: 100%;
    }

    .hp-title {
        font-size: 2rem;
    }

    .hp-upload-btn-container {
        display: block;
    }

    .hp-groups-section {
        /* order: -1; */
    }

    .hp-groups-container {
        display: block;
        width: 100%;
    }

    .hp-group-section {
        width: 100%;
    }

    .hp-group-btns-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 10px;
        gap: 10px;
    }
}



/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.announcement-item {
    font-size: 3vw;
    padding-top: 15px;
    padding-bottom: 15px;
}

.announcement-list {
    padding: 5%;
}

.blah {
    color: rgb(162, 162, 255);
    color: rgb(255, 163, 163);
    color: rgb(239, 255, 148);
    color: rgb(148, 255, 148);
    color: rgb(255, 148, 246);
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.color-group-wrapper {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    /* background-color: red; */
    flex-wrap: wrap;
    justify-content: center;
}

.group-section-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    width: 50vw;
}

.group-card {
    border-style: solid;
    border-radius: 15px;
    border-color: rgb(255, 255, 255);
    position: relative;
    transition: 1s ease-in-out all;
}

.signup-group-wrapper {
    width: 60%;
    padding-top: 25%;
}

.signup-wrapper {
    box-shadow: 2px 3px 10px white;
}

.signup-wrapper-None .signup-group-text {
    background-color: rgba(0, 0, 0, 0);
}

.group-text {
    color: white;
    margin: 0px;
    padding: 0px;
    font-family: 'Carter One', cursive;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.signup-group-text {
    font-size: 7.5vw;
}

.dismissed-groups {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    /* position: relative; */
}

.eaten-group-wrapper {
    width: 40%;
    padding-top: 13%;
    margin: 10px;
}

.eaten-group-text {
    font-size: 2.5vw;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.schedule-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.schedule-img {
    object-fit: cover;
    object-position: 50% 10%;
    height: 70%;
    border-radius: 10px;
}



/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.db-carousel, .db-carousel-full {
    overflow: hidden;
    background-color: rgb(38, 38, 87);
    background-color: black;
}

.db-carousel {
    height: 80vh;
}

.db-carousel-full {
    height: 100vh;
}

.db-img {
    /* object-fit: cover; */
    /* height: 100%; */
    object-position: 0px 0px;
}

.db-groups-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 20vh;
    /* gap: 2px; */
    background-color: rgb(87, 87, 87);
}

.db-groups-grid-item {
    background-color: black;
    display: flex;
    grid-gap: 2%;
    gap: 2%;
    align-items: center;
    padding: 3%;
}

.db-groups-title {
    font-size: 2.8vw;
    font-weight: bold;
}

.db-color-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 100%;
    flex-grow: 1;
    /* width: 70%; */
}

.db-color-grid-item {
    height: 100%;
    /* border-style: solid; */
    /* border-color: red; */
    display: flex;
    align-items: center;
    padding: 5%;
}

.db-grid-seconds {
    grid-column-start: 1;
    grid-column-end: 4;
}

.db-color-card {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 100%;
    border-radius: 4%;
    box-shadow: 3px 3px 5px rgb(70, 70, 70);
}

.db-color-grid-item:first-child .db-color-card {
    height: 100%;
    widtH: 100%;
}

.db-color-card-text {
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
    font-size: 2.3vw;
    color: white;
    padding: 0px;
    margin: 0px;
}

/* =========================================== */

.row1 {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row2 {
    height: 90vh;
    position: relative;
}

.row3 {
    height: 0vh;
}

.row {
}

.dash-column-space {
    height: 2%;
}

.dashboard-logo {
    object-fit: contain;
    width: 12vw;
}

.head-title, .dashboard-title {
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 3.5vw;
    margin-bottom: 20px;
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
}

.head-title {
    color: rgb(255, 131, 131);
}

.dashboard-title {
    font-size: 3.5vw
}

.dashboard-section {
    /* border-style: solid; */
    border-color: rgb(44, 44, 44);
    position: relative;
    overflow: hidden;
}

li {
    color: white;
    font-size: 1.5vw;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

* {
    box-sizing: border-box;
}
@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
   }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
   }
}
@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
   }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
   }
}
.ticker-wrap {
    position: relative;
    /* bottom: 0; */
    width: 100%;
    overflow: hidden;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding-left: 100%;
    box-sizing: content-box;
}
.ticker-wrap .ticker {
    display: inline-block;
    /* height: 4rem; */
    height: 100%;
    /* line-height: 4rem; */
    /* line-height: 100%; */
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 15s;
}
.ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 3vw;
    color: white;
}
/* body {
    padding-bottom: 5rem;
} */
/* h1, h2, p {
    padding: 0 5%;
} */

.forgot-modal {
    /* z-index: 4; */
}

.fp-modal .modal-content {
    /* z-index: 4; */
}
.auth-page {
    height: 100vh;
    width: 100vw;
    /* background-color: black; */
    padding: 20px;
    padding-top: 30px;
    display: flex;
    align-items: center;
}

.auth-background-img {
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    opacity: 0.7;
}

.auth-card {
    background-color: black;
    width: 100%;
}

.auth-header {
    text-align: center;
}

.auth-header, .auth-form-label {
    color: rgb(218, 218, 218);
}

.auth-subtitle {
    font-size: 0.8rem;
    text-align: center;
    color: rgb(209, 209, 209);
}

.auth-form-text {
    color: gray;
}

.auth-input {
    background-color: rgb(20, 20, 20);
    color: rgb(226, 226, 226);
    border-color: rgb(82, 82, 82);
}
.auth-input:focus {
    background-color: rgb(34, 34, 34);
    color: white;
}

.auth-links {
    margin-bottom: 15px;
}

.auth-submit-btn {
    width: 100%;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
    .auth-page {
        display: flex;
        justify-content: center;
    }
    .auth-card {
        width: 600px;
        margin-bottom: 100px;
    }
}


@media only screen and (min-width: 992px) {
    .auth-card-wrapper {
        margin-top: 40px;
    }
    .auth-card {
        width: 600px;
        background-color: rgb(17, 17, 17);
        box-shadow: 4px 4px 10px rgb(20, 20, 20);
        border-radius: 10px;
        padding: 30px;
        margin: 0px;
    }
}


@media only screen and (min-width: 1200px) {
}

.mli-container {
  width: 60%;
  min-height: 20%;
  background-color: rgb(37, 37, 37);
  border-radius: 30px;
  padding: 3vh;
}

.mli-title {
  color: white;
  text-align: center;
  font-size: 3.3vw;
  margin-bottom: 1.5vh;
}

.mli-border {
  height: 0.5vh;
  width: 100%;
  background-color: rgb(59, 59, 59);
  margin-bottom: 1.5vh;
}

.mli-content {
  padding-left: 5vh;
  padding-right: 5vh;
}

.mli-item {
  font-size: 3.5vh;
}

.mli-url {
  color: rgb(14, 197, 234);
}

.mli-code-container {
  min-height: 14vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mli-code {
  font-size: 9vh;
  color: white;
  text-align: center;
  letter-spacing: 1vh;
  font-weight: bold;
  -webkit-transform: scale(1, 1.3);
          transform: scale(1, 1.3);
  font-family: 'Fira Mono', monospace;
}

.mli-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

.mli-spinner {
  margin-right: 1vw;
}

.mli-btn {
  font-size: 4.4vh;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 20px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.su-input-label {
  color: rgb(208, 208, 208);
}

.su-code-input {
  background-color: black;
  color: rgb(186, 186, 186);
  border-color: rgb(69, 69, 69);
}

.su-code-input:active, .su-code-input:focus {
  background-color: rgb(24, 24, 24);
  color: white;
}

.su-btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}



/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
  .moon {
    background: linear-gradient(90deg, rgba(208,208,208,1) 48%, rgba(145,145,145,1) 100%);
    position: absolute;
    top: -100px;
    left: -300px;
    width: 900px;
    height: 900px;
    content: '';
    border-radius: 100%;
    box-shadow: 0px 0px 30px -4px rgba(0,0,0,0.5);
  }
  
  .moon__crater {
    position: absolute;
    content: '';
    border-radius: 100%;
    background: linear-gradient(90deg, rgba(122,122,122,1) 38%, rgba(195,195,195,1) 100%);
    opacity: 0.6;
  }
  
  .moon__crater1 {
    top: 250px;
    left: 500px;
    width: 60px;
    height: 180px;
  }
  
  .moon__crater2 {
    top: 650px;
    left: 340px;
    width: 40px;
    height: 80px;
    -webkit-transform: rotate(55deg);
            transform: rotate(55deg);
  }
  
  .moon__crater3 {
    top: -20px;
    left: 40px;
    width: 65px;
    height: 120px;
    -webkit-transform: rotate(250deg);
            transform: rotate(250deg);
  }
  
  .star {
    background: grey;
    position: absolute;
    width: 5px;
    height: 5px;
    content: '';
    border-radius: 100%;
    -webkit-transform: rotate(250deg);
            transform: rotate(250deg);
    opacity: 0.4;
    -webkit-animation-name: shimmer;
            animation-name: shimmer;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }
  
  @-webkit-keyframes shimmer {
    from {opacity: 0;}
    to {opacity: 0.7;}
  }
  
  @keyframes shimmer {
    from {opacity: 0;}
    to {opacity: 0.7;}
  }
  
  .star1 {
    top: 40%;
    left: 50%;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .star2 {
    top: 60%;
    left: 90%;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  .star3 {
    top: 10%;
    left: 70%;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  .star4 {
    top: 90%;
    left: 40%;
  }
  
  .star5 {
    top: 20%;
    left: 30%;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }

  .error-page-container {
    height: 130vh;
    width: 100vw;
    margin: 0px;
    background: linear-gradient(90deg, rgba(47,54,64,1) 23%, rgba(24,27,32,1) 100%);
  }
  
  .error {
    position: absolute;
    left: 100px;
    top: 400px;
    -webkit-transform: translateY(-60%);
            transform: translateY(-60%);
    font-family: 'Righteous', sans-serif;
    color: #363e49;
  }
  
  .error__title {
    font-size: 10em;
  }
  
  .error__subtitle {
    font-size: 2em;
  }
  
  .error__description {
    opacity: 0.5;
    max-width: 400px;
  }
  
  .error__button {
    min-width: 7em;
    margin-top: 3em;
    margin-right: 0.5em;
    padding: 0.5em 2em;
    outline: none;
    border: 2px solid #2f3640;
    background-color: transparent;
    border-radius: 8em;
    color: #576375;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 0.75em;
    font-family: 'Righteous', sans-serif;
  }
  
  .error__button:hover {
    color: #21252c;
  }
  
  .error__button--active {
    background-color: #e67e22;
    border: 2px solid #e67e22;
    color: white;
  }
  
  .error__button--active:hover {
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
    color: white;
  }
  
  .astronaut {
    position: absolute;
    width: 185px;
    height: 300px;
    left: 70%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
            transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
  }
  
  .astronaut__head {
    background-color: white;
    position: absolute;
    top: 60px;
    left: 60px;
    width: 60px;
    height: 60px;
    content: '';
    border-radius: 2em;
  }
  
  .astronaut__head-visor-flare1 {
    background-color: #7f8fa6;
    position: absolute;
    top: 28px;
    left: 40px;
    width: 10px;
    height: 10px;
    content: '';
    border-radius: 2em;
    opacity: 0.5;
  }
  
  .astronaut__head-visor-flare2 {
    background-color: #718093;
    position: absolute;
    top: 40px;
    left: 38px;
    width: 5px;
    height: 5px;
    content: '';
    border-radius: 2em;
    opacity: 0.3;
  }
  
  .astronaut__backpack {
    background-color: #bfbfbf;
    position: absolute;
    top: 90px;
    left: 47px;
    width: 86px;
    height: 90px;
    content: '';
    border-radius: 8px;
  }
  
  .astronaut__body {
    background-color: #e6e6e6;
    position: absolute;
    top: 115px;
    left: 55px;
    width: 70px;
    height: 80px;
    content: '';
    border-radius: 8px;
  }
  
  .astronaut__body__chest {
    background-color: #d9d9d9;
    position: absolute;
    top: 140px;
    left: 68px;
    width: 45px;
    height: 25px;
    content: '';
    border-radius: 6px;
  }
  
  .astronaut__arm-left1 {
    background-color: #e6e6e6;
    position: absolute;
    top: 127px;
    left: 9px;
    width: 65px;
    height: 20px;
    content: '';
    border-radius: 8px;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  
  .astronaut__arm-left2 {
    background-color: #e6e6e6;
    position: absolute;
    top: 102px;
    left: 7px;
    width: 20px;
    height: 45px;
    content: '';
    border-radius: 8px;
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
  }
  
  .astronaut__arm-right1 {
    background-color: #e6e6e6;
    position: absolute;
    top: 113px;
    left: 100px;
    width: 65px;
    height: 20px;
    content: '';
    border-radius: 8px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  
  .astronaut__arm-right2 {
    background-color: #e6e6e6;
    position: absolute;
    top: 78px;
    left: 141px;
    width: 20px;
    height: 45px;
    content: '';
    border-radius: 8px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
  }
  
  .astronaut__arm-thumb-left {
    background-color: #e6e6e6;
    position: absolute;
    top: 110px;
    left: 21px;
    width: 10px;
    height: 6px;
    content: '';
    border-radius: 8em;
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg);
  }
  
  .astronaut__arm-thumb-right {
    background-color: #e6e6e6;
    position: absolute;
    top: 90px;
    left: 133px;
    width: 10px;
    height: 6px;
    content: '';
    border-radius: 8em;
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  
  .astronaut__wrist-left {
    background-color: #e67e22;
    position: absolute;
    top: 122px;
    left: 6.5px;
    width: 21px;
    height: 4px;
    content: '';
    border-radius: 8em;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  
  .astronaut__wrist-right {
    background-color: #e67e22;
    position: absolute;
    top: 98px;
    left: 141px;
    width: 21px;
    height: 4px;
    content: '';
    border-radius: 8em;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  
  .astronaut__leg-left {
    background-color: #e6e6e6;
    position: absolute;
    top: 188px;
    left: 50px;
    width: 23px;
    height: 75px;
    content: '';
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  
  .astronaut__leg-right {
    background-color: #e6e6e6;
    position: absolute;
    top: 188px;
    left: 108px;
    width: 23px;
    height: 75px;
    content: '';
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  
  .astronaut__foot-left {
    background-color: white;
    position: absolute;
    top: 240px;
    left: 43px;
    width: 28px;
    height: 20px;
    content: '';
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    border-radius: 3px;
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
    border-bottom: 4px solid #e67e22;
  }
  
  .astronaut__foot-right {
    background-color: white;
    position: absolute;
    top: 240px;
    left: 111px;
    width: 28px;
    height: 20px;
    content: '';
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    border-radius: 3px;
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
    border-bottom: 4px solid #e67e22;
  }

}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

  
  
.firebase-emulator-warning {
  opacity: 0.0;
  pointer-events: none;
}

.root {
  min-height: 100%;
}

body {
  background-color: black;
}

.btn-has-screen {
  position: relative;
}

h2, h3 {
  color: white;
}

.btn-screen {
  opacity: 0;
  /* background-color: rgba(255, 0, 0, 0.397); */
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
}

.app-desktop-only {
  display: none;
}

/* Dark theme */
/* p, h1, h2 {
  color: white;
}

body {
  background-color: black;
}

.survey-item-container {
  background-color: rgb(27, 27, 27);
} */
/* end dark theme */

.page-content {
  min-height: calc(100vh - 110px);
}

.app-header-cushion-top, .app-header-cushion-bottom {
  width: 100%;
  height: 22px;
}

.app-header-cushion-top {
  /* header color */
  background-color: rgb(248, 249, 250);
  /* background-color: white; */
  position: fixed;
  top: 0;
  z-index: 100;
}

.text-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

p {
  font-size: 14px;
}

/* #root {
  color: white;
  background-color: black;
} */

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
  .text-wrapper {
      padding-left: 30px;
      padding-right: 20vw;
  }
  
  p {
      font-size: 15px;
  }

}


@media only screen and (min-width: 1200px) {
  .text-wrapper {
      padding-left: 30px;
      padding-right: 40vw;
      margin-top: 50px;
      margin-bottom: 50px;
  }
  
  p {
      font-size: 15px;
  }

  .app-desktop-only {
    display: block;
  }
}

