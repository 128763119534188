.epm-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.epm-btn-wrapper {
}

.epm-btn {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-style: none;
  box-shadow: 0px 0px 8px gray;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.epm-btn:active {
  box-shadow: 0px 0px 0px white;
}

.epm-vis-btn:active {
  background-color: rgb(210, 210, 210);
}

.epm-del-btn:active {
  background-color: rgb(171, 40, 54);
}

.epm-del-btn {
  background-color: rgb(220, 53, 69);
}

.epm-vis-btn-hidden {
  background-color: rgb(94, 94, 94)
}

.epm-btn-icon {
  font-size: 2rem;
}

.epm-btn-icon-white {
  color: white;
}

.epm-btn-title {
  font-size: 1rem;
  text-align: center;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
