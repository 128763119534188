.mli-container {
  width: 60%;
  min-height: 20%;
  background-color: rgb(37, 37, 37);
  border-radius: 30px;
  padding: 3vh;
}

.mli-title {
  color: white;
  text-align: center;
  font-size: 3.3vw;
  margin-bottom: 1.5vh;
}

.mli-border {
  height: 0.5vh;
  width: 100%;
  background-color: rgb(59, 59, 59);
  margin-bottom: 1.5vh;
}

.mli-content {
  padding-left: 5vh;
  padding-right: 5vh;
}

.mli-item {
  font-size: 3.5vh;
}

.mli-url {
  color: rgb(14, 197, 234);
}

.mli-code-container {
  min-height: 14vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mli-code {
  font-size: 9vh;
  color: white;
  text-align: center;
  letter-spacing: 1vh;
  font-weight: bold;
  transform: scale(1, 1.3);
  font-family: 'Fira Mono', monospace;
}

.mli-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

.mli-spinner {
  margin-right: 1vw;
}

.mli-btn {
  font-size: 4.4vh;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 20px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
