.auth-page {
    height: 100vh;
    width: 100vw;
    /* background-color: black; */
    padding: 20px;
    padding-top: 30px;
    display: flex;
    align-items: center;
}

.auth-background-img {
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    opacity: 0.7;
}

.auth-card {
    background-color: black;
    width: 100%;
}

.auth-header {
    text-align: center;
}

.auth-header, .auth-form-label {
    color: rgb(218, 218, 218);
}

.auth-subtitle {
    font-size: 0.8rem;
    text-align: center;
    color: rgb(209, 209, 209);
}

.auth-form-text {
    color: gray;
}

.auth-input {
    background-color: rgb(20, 20, 20);
    color: rgb(226, 226, 226);
    border-color: rgb(82, 82, 82);
}
.auth-input:focus {
    background-color: rgb(34, 34, 34);
    color: white;
}

.auth-links {
    margin-bottom: 15px;
}

.auth-submit-btn {
    width: 100%;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
    .auth-page {
        display: flex;
        justify-content: center;
    }
    .auth-card {
        width: 600px;
        margin-bottom: 100px;
    }
}


@media only screen and (min-width: 992px) {
    .auth-card-wrapper {
        margin-top: 40px;
    }
    .auth-card {
        width: 600px;
        background-color: rgb(17, 17, 17);
        box-shadow: 4px 4px 10px rgb(20, 20, 20);
        border-radius: 10px;
        padding: 30px;
        margin: 0px;
    }
}


@media only screen and (min-width: 1200px) {
}
